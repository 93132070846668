import { initializeApp } from "firebase/app"; 

const firebaseConfig = {
  apiKey: 'AIzaSyCf4N7P0XrOaaJDAcM0cj7UVPpuRCga3cw',
  authDomain: 'first-c8159.firebaseapp.com',
  projectId: 'first-c8159',
  storageBucket: 'first-c8159.appspot.com',
  messagingSenderId: '203493023468',
  appId: '1:203493023468:web:fa5217cb19674a521d8fc9',
};

const app = initializeApp(firebaseConfig);
export default app;