import { createI18n } from "vue3-i18n";

const messages = {
  en: {
    lang: "Русский",
    menu: ["Главная", "Каталог", "О нас", "Контакты"],
    contactButton: "Связаться",
    address: "ул. Панфилова 75",
    city: "г. Алматы",
    productsNav: "Посмотреть товары",
    heroSection: {
      title: "Фризер от известного бренда",
      subtitle:
        "Надёжные холодильники и морозильные камеры по низким ценам! Наш опыт — ваше уверенное решение для бытовых нужд! Почему покупка холодильного оборудования у нас — это хорошая идея?" + "\n" + " • Продажа оптом и в розницу." + "\n" + " • 1 год гарантии на все модели. " + "\n" + "• Доставка по всему Казахстану. Наши сотрудники помогут вам разобраться, какое оборудование подойдёт для вашего помещения, найдут его в наличии в нашем магазине! Обустраивайте не только жилые дома и квартиры, но и предприятия общественного питания — мы предоставим всё необходимое оборудование.",
      getStartedButton: "Оставить заявку",
      ExploreButton: "Смотреть",
    },
    aboutUs: {
      title: "О нас",
    },
    chooseSection: {
      title: "Видео инструкции",
      interestedSection: "Вам интересно работать вместе?",
      cardOne: {
        title: "Legal",
        subtitle:
          "We are legal and certified from our government for business activity and export industries",
      },
      cardTwo: {
        title: "Best Price",
        subtitle:
          "We are legal and certified from our government for business activity and export industries",
      },
      cardThree: {
        title: "Trusted",
        subtitle:
          "We are legal and certified from our government for business activity and export industries",
      },
    },
    productSection: {
      smallTitle: "Let's check",
      bigTitle: "Наши товары",
      productSmallTitle: "Our main product are",
      productTitle: "JAMBO BQL-9228",
      description:
        "Areca nut is a plant belonging to palms. This tree is one of the plants with high economic value and potential. Areca nut is mainly grown for its seeds. Areca nut has many benefits, so that currently areca nut has become a trading commodity.",
      variantTitle: "We export one of kind",
      variant: "BGL 777",
      variantDescription:
        "Various types of varieties that exist, namely, areca betara, areca red, areca bulawan, areca palm and areca nut irian.",
      getProductTitle: "Get our areca nuts now",
    },
    getTouchSection: {
      mainTitle: "Связаться",
      title: "Есть предложения?",
      subtitle:
        "Свяжитесь с нами или посетите или социальные сети ниже.",
      name: "Имя",
      mail: "Email",
      phone: "Номер телефона",
      message: "Сообщение",
      submit: "Отправить",
    },
    contactPage: {
      mainTitleSub: "Свяжитесь с нами, если у вас есть какие-либо вопросы или что-либо о бизнесе",
      contactInformation: "Контактный данные",
      regency: "Алматы",
      phone: "+7 747 465 59 77",
      mail: "yeldos@nu.edu.kz",
    },
    aboutPage: {
      title: "Know",
      description: "Добро пожаловать в мир мороженных десертов от Jambo! Наша компания предлагает самые современные и надежные мороженные аппараты, которые помогут вам начать свой бизнес в области мороженого.\n\n" +
          "Наши аппараты предназначены для профессионалов и любителей мороженого. Они идеально подходят для кафе, ресторанов, фудтраков и любых других заведений, которые хотят предложить своим клиентам настоящее и вкусное мороженое.\n\n" +
          "Наша продукция соответствует самым высоким стандартам качества. Мы используем только лучшие материалы и самые современные технологии производства. Наши мороженные аппараты обеспечивают быстрое охлаждение и удобную работу.\n\n" +
          "Мы предлагаем широкий выбор мороженых рецептов, которые позволяют создавать самые вкусные и оригинальные десерты. Вы можете выбрать из большого количества вкусов и добавок, чтобы удовлетворить вкусы даже самых взыскательных клиентов.\n\n" +
          "Если вы хотите начать свой бизнес в области мороженого, то мы предлагаем вам нашу помощь. Наши специалисты помогут вам выбрать наилучший аппарат для вашего заведения, а также предоставят всю необходимую консультацию и поддержку.",
      thankGreeting: "We are very grateful for all our partners and clients, Following the moment and will always serve the best for all.",
      visi: {
        title: "Vision",
        desc: "Become an exporter with world quality customer service and advance the economy together",
      },
      misi: {
        title: "Mision",
        misi1: "Build good relationships with clients and partners",
        misi2: "Always provide requests with good quality and guaranteed",
        misi3: "Build and have a professional team"
      }
    }
  },
  id: {
    lang: "Қазақша",
    menu: ["Басты бет", "Товарлар", "Біз туралы", "Контакттар"],
    contactButton: "Хабарласу",
    address: "Панфилова 75",
    city: "Алматы қ",
    productsNav: "Товарларды қарау",
    heroSection: {
      title: "Eksportir Pinang Kualitas Terpercaya",
      subtitle:
        "Kami adalah eksportir produk di Kepulauan Meranti. Salah satunya adalah pinang kualitas tinggi khas lahan gambut Kepulauan Meranti",
      getStartedButton: "Бастау",
      ExploreButton: "Қарау",
    },
    chooseSection: {
      title: "Неге біз?",
      interestedSection: "Бізбен жұмыс істегіңіз келсе",
      cardOne: {
        title: "Resmi dan Bersertifikat",
        subtitle:
          "Kami legal dan bersertifikat dari pemerintah kami untuk kegiatan bisnis dan industri ekspor",
      },
      cardTwo: {
        title: "Harga Terbaik",
        subtitle:
          "Kami menawarkan harga yang kompetitif dan nilai terbaik untuk bisnis Anda. Hubungi untuk informasi lebih lanjut",
      },
      cardThree: {
        title: "Terpercaya",
        subtitle:
          "Anda akan mendapatkan produk terbaik dengan kualitas terbaik yang pernah kami miliki, dan nilai terbaik untuk harga berapa pun",
      },
    },
    productSection: {
      smallTitle: "Ayo periksa",
      bigTitle: "Produk Kami",
      productSmallTitle: "Produk utama kami adalah",
      productTitle: "Biji Buah Pinang",
      description:
        "Buah pinang adalah tumbuhan yang tergolong palem-paleman. Pohon ini merupakan salah satu tanaman dengan nilai ekonomi dan potensi yang cukup tinggi. Pinang terutama ditanam untuk dimanfaatkan bijinya. Pinang sangat banyak manfaatnya, sehingga saat ini biji buah pinang sudah menjadi komoditas perdagangan.",
			variantTitle: "Kami mengekspor berbagai jenis",
      variant: "Biji buah pinang",
      variantDescription:
        "Berbagai jenis varietas yang ada yaitu, pinang betara, pinang merah, pinang bulawan, pinang kelapa dan pinang irian",
      getProductTitle: "Dapatkan biji kopi liberika kami sekarang",
    },
    getTouchSection: {
      mainTitle: "Hubungi Kami",
      title: "Mempunyai pertanyaan?",
      subtitle:
        "Hubungi kami dan tanyakan sesuatu. Atau hubungi kami di link sosial media dibawah",
      name: "Аты",
      mail: "Email",
      phone: "Номер телефона",
      message: "Хаттар",
      submit: "Жіберу",
    },
    contactPage: {
      mainTitleSub: "Hubungi kami jika Anda memiliki pertanyaan atau apa pun tentang bisnis",
      contactInformation: "Informasi Kontak",
      regency: "Алматы",
      phone: "+628 0000 0000",
      mail: "alamat@email.com",
    },
    aboutPage: {
      title: "Ketahui",
      description: "Config adalah pengekspor komoditas unggulan di salah satu kabupaten di Provinsi Riau, yaitu Kepulauan Meranti. dibangun oleh orang-orang yang memiliki visi yang sama, dan mengelola untuk melayani klien dengan profesional.",
      thankGreeting: "Kami sangat berterima kasih untuk semua mitra dan klien kami, Mengikuti momen dan akan selalu melayani yang terbaik untuk semua.",
      visi: {
        title: "Visi",
        desc: "Menjadi pengekspor dengan layanan pelanggan kualitas dunia dan memajukan ekonomi bersama",
      },
      misi: {
        title: "Misi",
        misi1: "Membangun hubungan baik bersama client dan partner",
        misi2: "Selalu menyediakan permintaan dengan kualitas baik dan terjamin",
        misi3: "Membangun dan memiliki tim yang profesional"
      }
    }
  },
};

const i18n = createI18n({
  locale: "en",
  messages: messages,
});

export default i18n;
