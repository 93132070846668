import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/styles.css"
import i18n from "./lang/i18n"
import YmapPlugin from 'vue-yandex-maps'

const settings = {
    apiKey: '6a55fd78-037a-4cd1-8459-50d464b37c22',
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
}

createApp(App).use(store).use(router).use(i18n).use(YmapPlugin, settings).mount('#app');